import React from "react";


export default function HelpRow({title, description, onLinkClick}) {

    return (
        <div className={"help-row"} onClick={onLinkClick}>
            <div className={"title"}>
                {title}
            </div>
            <div className={"description"}>
                {description}
            </div>
        </div>
    )

}
