import React, {useContext, useEffect, useMemo} from "react";
import {LocalizationContext} from "../../common/Locaization";
import {useNavigate, useParams, useSearchParams} from "react-router-dom";

export default function Faq() {
    const localization = useContext(LocalizationContext);
    const navigation = useNavigate();
    const {id} = useParams();
    const [searchParams] = useSearchParams();
    const content = useMemo(() => {
        let faqData
        if (localization.language == "ru") {
            faqData = require("../../faq/ru.json")
        } else {
            faqData = require("../../faq/en.json")
        }
        if (!id) {
            return faqData[Object.keys(faqData)[0]];
        }
        return faqData[id];

    }, [id]);

    useEffect(() => {
        const sectionIdx = searchParams.get("section");
        if (sectionIdx) {
            const section = document.querySelectorAll(".faq-content h1");
            if (section.length > 0 && sectionIdx < section.length) {
                section[sectionIdx].scrollIntoView();
            }
        } else {
            window.scrollTo(0, 0);
        }
    }, [searchParams]);


    return (<div className={"container page"}>
        <div className={"content"}>
            <div className={"bundle-header"}>{content.title}</div>
            <div className={"faq-content"} onClick={(e) => {
                const target = e.target;
                if (target.tagName === "A") {
                    e.preventDefault();
                    navigation("/faq/" + e.target.rel)

                }
            }} dangerouslySetInnerHTML={{__html: content.content}}/>
        </div>
    </div>)
}
