import config from "../config";
import * as Sentry from "@sentry/react";

const registry_url = '/api/esim/';

async function Call(method, data = null) {

    const headers = {
        'Content-Type': 'application/json',
    }

    if (config.telegram) {
        if (config.dev) {
            headers.InitData = config.devKey;
        } else {
            headers.InitData = window.Telegram.WebApp.initData;

        }
    }

    try {
        const result = await fetch(registry_url + method, {
            method: data == null ? 'GET' : 'POST',
            headers,
            body: data == null ? null : JSON.stringify(data)
        })
        return await result.json();
    } catch (e) {
        if (config.errorTracking) {
            Sentry.captureMessage('API error: ' + method);
            Sentry.captureException(e);
        }
    }
}

export async function GetBundles() {
    return Call('bundles');
}

export async function GetCountries() {
    return Call('countries');
}

export async function GetBundleTariffs(id) {
    return Call('tariffs?bundleId=' + id);
}

export async function GetTariff(id) {
    return Call('tariff?id=' + id);
}

export async function CreateOrder(tariffId, paymentMethodId, source, balanceAmount, promoCode) {
    return Call('orders',
        {
            tariffId: tariffId,
            paymentMethodId: paymentMethodId,
            source: source,
            balanceAmount: balanceAmount,
            promoCode: promoCode
        }
    );
}

export async function CreateDepositPayment(amount, paymentMethodId, source) {
    return Call('deposit/payment',
        {
            amount: amount,
            paymentMethodId: paymentMethodId,
            source: source
        }
    );
}


export async function GetOrder(id) {
    return Call('orders/' + id);
}

export async function GetOrders() {
    return Call('orders');
}

export async function PaymentStatus(id) {
    return Call('payments/' + id + '/status');
}

export async function OrderStatus(id) {
    return Call('orders/' + id + '/status');
}

export async function GetSimCard(id) {
    return Call('simcards/' + id);
}

export async function GetPaymentMethods() {
    return Call('payment-methods');
}

export async function GetUserData() {
    return Call('user');
}

export async function GetWebUserData() {
    return Call('user/webauth', {});
}

export async function TonAuth(walletStateInit, address, walletAddress) {
    return Call('user/tonauth', {
        WalletStateInit: walletStateInit,
        Address: address,
        WalletAddress: walletAddress,
        Locale: navigator.language
    });
}

export async function GetExpressCheckout() {
    return Call('payments/express');
}

export async function PayPayment(id, express, additionalName) {
    return Call(`payments/${id}/pay`,
        {
            express: express,
            additionalName: additionalName
        }
    );
}

export async function GetNotificationToken() {
    return Call('notification-token');
}

export async function GetSimCards() {
    return Call('simcards');
}


export async function SaveProfile({language, theme}) {
    return Call('user/profile', {language, theme});
}

export async function ActivatePromoCode(code) {
    return Call('deposit/promocode', {promocode: code});
}

export async function DepositHistory() {
    return Call('deposit/history');
}

export async function GetUserActivePromoCode() {
    return Call('user/promocode');
}

export async function GetReferralInfo() {
    return Call('user/referral');
}
