import BundleFlag from "./BundleFlag";
import {LocalizationContext} from "../common/Locaization";
import React, {useContext} from "react";

export default function BundleHeader({bundle, iconName, name, vertical = false}) {
    const localization = useContext(LocalizationContext);

    return (<p className={"bundle-header" + (vertical ? " vertical" : "")}>
        <BundleFlag
            iconName={bundle?.iconName ?? iconName}/>
        <span>{localization.GetLocalized(bundle?.name ?? name)}</span>

    </p>)
}
