import {CopyToClipboard} from "react-copy-to-clipboard/src";
import {useContext, useState} from "react";
import {LocalizationContext} from "../common/Locaization";
import copyIcon from "../assets/copy.svg";
import copySuccessIcon from "../assets/copied.svg";

export default function CopyRow({children, text, flex = true}) {
    const [copied, setCopied] = useState(false);
    const localization = useContext(LocalizationContext);

    return (<CopyToClipboard text={text} onCopy={() => {
        setCopied(true);
        setTimeout(() => {
            setCopied(false);
        }, 2000);
    }}>
        <div className={"copy " + (!flex ? "no-flex" : "")}>
            {children}
            <div
                className={"tooltip"}>{copied ? localization.GetString("copied") : localization.GetString("copy")}
                <img src={copied ? copySuccessIcon : copyIcon} alt={"copy"}/>
            </div>
        </div>
    </CopyToClipboard>)
}
