import React, {useContext, useEffect, useMemo, useState} from "react";
import {useNavigate, useParams} from "react-router-dom";
import {DataStateContext} from "../context/dataState";
import {LocalizationContext} from "../common/Locaization";
import PaymentMethods from "../elements/PaymentMethods";
import {CreateOrder, GetPaymentMethods, GetTariff, GetUserActivePromoCode, PayPayment} from "../common/Api";
import BundleHeader from "../elements/BundleHeader";
import {AppStateContext} from "../context/appState";
import {LoadingSpinner} from "../components/LoadingSpinner";
import config from "../config";
import balanceLogo from "../assets/balance-logo.svg";
import ProgressLogo from "../assets/progress-logo.svg";
import PromoCodeActivation from "../components/PromoCodeActivation";
import {Elements} from '@stripe/react-stripe-js';
import {loadStripe} from '@stripe/stripe-js';
import StripeCheckout from "../components/StripeCheckout";


export default function BundleCheckout() {
    const [dataState, dispatchDataState] = useContext(DataStateContext);
    const [appState, dispatchAppState] = useContext(AppStateContext);
    const localization = useContext(LocalizationContext);
    const [paymentMethods, setPaymentMethods] = useState(null);
    const {id, tariffId} = useParams();
    const navigation = useNavigate();
    const [bundle, setBundle] = useState(null);
    const [tariff, setTariff] = useState(null);
    const [loading, setLoading] = useState(false);
    const [balanceUse, setBalanceUse] = useState(false);

    const [forceBalanceUse, setForceBalanceUse] = useState(false);
    const [discountCode, setDiscountCode] = useState(null);
    const stripePromise = useMemo(() => {
        if (dataState.expressCheckout?.publicKey) {
            return loadStripe(dataState.expressCheckout.publicKey);
        }
        return null;
    }, [dataState.expressCheckout]);
    const discountAmount = useMemo(() => {
        if (discountCode == null || tariff == null) {
            return 0;
        }
        return Math.round(tariff.price * discountCode.amount) / 100;
    }, [discountCode, tariff]);
    const balanceUseAmount = useMemo(() => {
        if (balanceUse) {
            return Math.round(Math.min(dataState.userData.balance, tariff.price - discountAmount) * 100) / 100;
        }
        return 0;
    }, [balanceUse, dataState.userData.balance, discountAmount, tariff]);

    const payAmount = useMemo(() => {
        if (tariff == null) {
            return 0;
        }
        return Math.round((tariff.price - balanceUseAmount - discountAmount) * 100) / 100;
    }, [tariff, balanceUseAmount, discountAmount]);

    const updateUserDiscount = () => {
        GetUserActivePromoCode().then((data) => {
            setDiscountCode(data);
        });
    }

    useEffect
    (() => {
        dispatchAppState({type: 'setNeedUserRefresh', payload: true});
        updateUserDiscount();
    }, [dispatchAppState]);

    useEffect(() => {
        setBundle(dataState.bundles?.find(b => b.id == id));

    }, [id, dataState.bundles]);
    useEffect(() => {

        let tariff = dataState.tariffs?.find(b => b.id == tariffId);
        if (tariff) {
            setTariff(tariff);
            return;
        }
        GetTariff(tariffId).then(data => {
            setTariff(data);
        });

    }, [tariffId, dataState.tariffs]);

    useEffect(() => {
        if (paymentMethods || config.tonkeeper) {
            return;
        }

        GetPaymentMethods().then(data => {
            setPaymentMethods(data);
        });

    }, [dispatchDataState, localization, paymentMethods]);

    useEffect(() => {
        if (forceBalanceUse && dataState.userData.balance > 0) {
            setBalanceUse(true);
            setForceBalanceUse(false);
        }
    }, [forceBalanceUse, tariff, dataState.userData.balance]);

    useEffect(() => {
        if (!loading && (!payAmount || config.tonkeeper)) {
            dispatchDataState({
                type: 'setMainButton', payload: {
                    text: localization.GetString("purchase_button"), onClick: () => {
                        startOrder(null);
                    }
                }
            });
            dispatchDataState({type: 'setMainButtonVisible', payload: true})
        } else {
            dispatchDataState({type: 'setMainButtonVisible', payload: false})
        }
        return () => {
            dispatchDataState({type: 'setMainButton', payload: null});
            dispatchDataState({type: 'setMainButtonVisible', payload: false})
        }
    }, [dispatchDataState, payAmount, loading]);

    const startOrder = async (paymentMethod) => {
        setLoading(true);
        CreateOrder(tariffId, paymentMethod?.id, window.source, balanceUseAmount, discountCode?.code).then((data) => {
            if (data == null) {
                //ToDo: show error
                return;
            }
            dispatchAppState({type: 'needUserRefresh', payload: true});
            if (data.status == "created") {
                navigation(`/pay?id=${data.paymentId}&target=order&targetId=${data.id}`);
            } else {
                navigation(`/order/${data.id}/processing`);
            }
        });
    }

    return (<div className={"container page"}>
        <div className={"content"}>
            {loading ? (<div className={"info-page"}>
                <img src={ProgressLogo}/>

                <h2>{localization.GetString("order-progress")}</h2>
                <p>{localization.GetString("order-progress-text")}</p>
            </div>) : (bundle == null || tariff == null) ? (<LoadingSpinner/>) : (<>
                <div className={"tariff-details"}>
                    <BundleHeader bundle={bundle}/>
                    <p className={"bundle-subtitle"}>
                        {tariff.unlimited ? "∞" : tariff.dataSize} {localization.GetString("gb")} / {tariff.days} {localization.GetString("days")}
                    </p>
                    <div className={"header-separator"}/>
                    {(discountCode && discountCode.amount > 0) && (<div className={"price-row accented"}>
                        <div className={"title"}>
                            {localization.GetString("details_discount_code")} {discountCode.code}
                        </div>
                        <div className={"value"}>
                            -$ {discountAmount}
                        </div>
                    </div>)}
                    {balanceUseAmount > 0 && (<div className={"price-row accented"}>
                        <div className={"title"}>
                            {localization.GetString("details_deposit")}
                        </div>
                        <div className={"value"}>
                            -$ {balanceUseAmount}
                        </div>
                    </div>)}

                    <div className={"price-row"}>
                        <div className={"title"}>
                            {localization.GetString("details_total")}
                        </div>
                        <div className={"value"}>
                            {(balanceUseAmount > 0 || discountAmount > 0) && (
                                <span className={"previous-price"}> $ {tariff.price} </span>)}$ {payAmount}
                        </div>
                    </div>

                </div>
                <PromoCodeActivation onSuccess={(result) => {
                    if (!(result?.amount > 0)) {
                        return;
                    }
                    if (result.type === "deposit") {
                        setForceBalanceUse(true);
                    }
                    if (result.type === "discount") {
                        updateUserDiscount();
                    }

                }}/>

                <div onClick={() => {
                    if (!balanceUse && dataState.userData.balance > 0) {
                        setBalanceUse(true)
                    } else {
                        setBalanceUse(false);
                    }
                }}
                     className={"balance-row"}>

                    <div style={{backgroundImage: `url(${balanceLogo})`}} className={"icon"}/>
                    <div className={"label"}>{localization.GetString("use_deposit")}
                        <div className={"value"}>{localization.GetString("use_deposit_available")}:
                            $ {dataState.userData.balance}</div>
                    </div>
                    <div className={"switcher " + (balanceUseAmount > 0 && "on")}/>

                </div>
                {(!config.tonkeeper && payAmount > 0) ? (<>
                        {stripePromise && (<Elements stripe={stripePromise} options={{
                            mode: 'payment', currency: 'usd', amount: Math.round(payAmount * 100), appearance: {},
                        }}>
                            <StripeCheckout tariffId={tariffId} payAmount={payAmount} balanceUse={balanceUseAmount}
                                            discountCode={discountCode}/>
                        </Elements>)}
                        <PaymentMethods paymentMethods={paymentMethods} onSelect={(paymentMethod) => {
                            startOrder(paymentMethod);
                        }} bundle={bundle}/></>
                ) : (<></>
                )}

            </>)}
        </div>
    </div>);
}
