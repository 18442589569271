import React, {useContext, useEffect, useState} from "react";
import {GetReferralInfo} from "../../common/Api";
import {LoadingSpinner} from "../../components/LoadingSpinner";
import referralImg from "../../assets/referral.png";
import {LocalizationContext} from "../../common/Locaization";
import referralFriends from "../../assets/referral-friends.svg";
import referralReward from "../../assets/referral-reward.svg";
import CopyRow from "../../components/Copy";
import icon1 from "../../assets/referral_icon1.svg";
import icon2 from "../../assets/referral_icon2.svg";
import icon3 from "../../assets/referral_icon3.svg";
import {DataStateContext} from "../../context/dataState";
import config from "../../config";

export default function Referral() {
    const localization = useContext(LocalizationContext)
    const [referralInfo, setReferralInfo] = useState("");
    const [dataState, dispatchDataState] = useContext(DataStateContext);

    useEffect(() => {
        if (!referralInfo) {
            GetReferralInfo().then((data) => {
                setReferralInfo(data);
            });
            return;

        }
        dispatchDataState({
            type: 'setMainButton', payload: {
                text: localization.GetString("referral_share"), onClick: () => {
                    window.Telegram.WebApp.openTelegramLink("https://t.me/share/url?url=" + config.shareLink + referralInfo.referralCode + "&text=" + encodeURIComponent(localization.GetString("referral_share_text", {code: referralInfo.referralCode})));
                }
            }
        })
        dispatchDataState({type: 'setMainButtonVisible', payload: true})


        return () => {
            dispatchDataState({type: 'setMainButtonVisible', payload: false})
            dispatchDataState({type: 'setMainButton', payload: null})
        }

    }, [referralInfo]);

    if (!referralInfo) {
        return (<div className={"container page"}>
            <div className={"content"}><LoadingSpinner/></div>
        </div>)
    }

    return (<div className={"container page"}>
            <div className={"content"}>
                <div className={"referral-top"}>
                    <img src={referralImg} className={"image"}/>
                    <div className={"info"}>
                        <div className={"title"}>{localization.GetString("referral_title")}</div>
                        {localization.GetString("referral_subtitle", {amount: referralInfo.referrerReward})}
                    </div>
                </div>
                <div className={"referral-stats"}>
                    <div className={"stat"}>
                        <img className={"image"} src={referralFriends}/>
                        <div className={"info"}>
                            <div className={"title"}>{localization.GetString("referral_invited")}</div>
                            <div className={"value"}>{referralInfo.referralsCount}</div>
                        </div>
                    </div>
                    <div className={"stat"}>
                        <img className={"image"} src={referralReward}/>
                        <div className={"info"}>
                            <div className={"title"}>{localization.GetString("referral_reward_received")}</div>
                            <div className={"value"}>$ {referralInfo.referralRewardReceived}</div>
                        </div>
                    </div>


                </div>
                <div className={"referral-code"}>
                    <CopyRow text={referralInfo.referralCode}>
                        <div className={"info"}>
                            <div className={"title"}>{localization.GetString("referral_code")}</div>
                            <div className={"value"}>{referralInfo.referralCode}</div>
                        </div>
                    </CopyRow>
                </div>

                <div className={"referral-description"}>
                    <div className={"item"}>
                        <img src={icon1} className={"image"}/>
                        <div className={"info"}>
                            <div className={"title"}>{localization.GetString("referral_description1_title")}</div>
                            <div className={"description"}>{localization.GetString("referral_description1")}</div>
                        </div>
                    </div>
                    <div className={"item"}>
                        <img src={icon2} className={"image"}/>
                        <div className={"info"}>
                            <div
                                className={"title"}>{localization.GetString("referral_description2_title", {discount: referralInfo.referralDiscount})}</div>
                            <div
                                className={"description"}>{localization.GetString("referral_description2", {discount: referralInfo.referralDiscount})}</div>
                        </div>
                    </div>
                    <div className={"item"}>
                        <img src={icon3} className={"image"}/>
                        <div className={"info"}>
                            <div
                                className={"title"}>{localization.GetString("referral_description3_title", {amount: referralInfo.referrerReward})}</div>
                            <div
                                className={"description"}>{localization.GetString("referral_description3", {amount: referralInfo.referrerReward})}</div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
