let configValues;

if (process.env.REACT_APP_ENV === "PROD") {
    configValues = require('./config.PROD.js').default;
}if (process.env.REACT_APP_ENV === "NEWPROD") {
    configValues = require('./config.NEWPROD.js').default;
} else if (process.env.REACT_APP_ENV === "TEST") {
    configValues = require('./config.TEST.js').default;
}
else if (process.env.REACT_APP_ENV === "WEB") {
    configValues = require('./config.WEBSITE.js').default;
}
else if (process.env.REACT_APP_ENV === "WEBPROD") {
    configValues = require('./config.WEBSITE-PROD.js').default;
}
else if (process.env.REACT_APP_ENV === "TONKEEPER") {
    configValues = require('./config.TONKEEPER.js').default;
} else {
    configValues = require('./config.DEV.js').default;
}

export default configValues;

