import React, {useContext, useEffect, useMemo, useState} from "react";
import {DataStateContext} from "../context/dataState";
import {LoadingSpinner} from "../components/LoadingSpinner";
import BundleHeader from "../elements/BundleHeader";
import {LocalizationContext} from "../common/Locaization";
import {AppStateContext} from "../context/appState";
import config from "../config";
import {useNavigate, useParams} from "react-router-dom";
import {useTonConnectUI, useTonWallet} from "@tonconnect/ui-react";
import {GetBundleTariffs} from "../common/Api";
import {Tab, TabList, Tabs} from "react-tabs";

export default function Bundle() {
    const localization = useContext(LocalizationContext);
    const wallet = useTonWallet();
    const [tonConnectUI] = useTonConnectUI();

    const [appState, dispatchAppState] = useContext(AppStateContext);
    const [dataState, dispatchDataState] = useContext(DataStateContext);
    const [tariffs, setTariffs] = useState(null);
    const [unlimitedTariffs, setUnlimitedTariffs] = useState(null);
    const [selectedTariff, setSelectedTariff] = useState(null);
    const {id} = useParams();
    const navigation = useNavigate();
    const [showCountries, setShowCountries] = useState(false);
    const [bundle, setBundle] = useState(null);
    const [tariffType, setTariffType] = useState(0);

    useEffect(() => {
        setBundle(dataState.bundles?.find(b => b.id == id));
    }, [id, dataState.bundles]);

    const countryNames = useMemo(() => {
        if (bundle?.countries == null || !dataState.countries) {
            return [];
        }

        let ret = dataState.countries.filter(country => bundle.countries.includes(country.code)).map(country => localization.GetLocalized(country.name));
        ret = ret.sort();
        return ret;

    }, [bundle, dataState.countries]);

    const unlimited = useMemo(() => {
        if (!tariffs) {
            return false;
        }
        return tariffs.some(t => t.unlimited);
    }, [tariffs])
    const traiffsToShow = () => {
        return (tariffType == 0 && tariffs.length > 0) ? tariffs : unlimitedTariffs;
    }

    const goToCheckout = () => {

        if (config.tonkeeper && !wallet) {
            tonConnectUI.openSingleWalletModal("tonkeeper")
        } else {
            navigation("/bundle/" + bundle.id + "/" + selectedTariff.id);
        }

    }

    useEffect(() => {
        if (bundle != null && selectedTariff != null) {

            let buttonText;
            if (config.tonkeeper && !wallet) {
                buttonText = localization.GetString("connect_ton");
            } else {
                buttonText = localization.GetString("go_to_checkout");
            }

            dispatchDataState({
                type: 'setMainButton', payload: {text: buttonText, onClick: goToCheckout}
            });
            return () => {
                dispatchDataState({type: 'setMainButton', payload: null});
            }
        }
    }, [bundle, wallet, selectedTariff]);

    useEffect(() => {
        if (!bundle) {
            return;
        }
        GetBundleTariffs(bundle.id).then(data => {
            data = data.sort((a, b) => a.dataSize - b.dataSize || a.days - b.days);
            let minTariff = null;
            let minUnlimitedTariff = null;
            let tariffs = [];
            let unlimitedTariffs = [];
            let allTariffs = [];
            for (let tariff of data) {
                if (tariff.unlimited) {
                    if (!minUnlimitedTariff) {
                        minUnlimitedTariff = tariff;
                    }
                    let tariffDiscount = Math.round((minUnlimitedTariff.price / minUnlimitedTariff.days - tariff.price / tariff.days) / minUnlimitedTariff.price * 100);
                    let dayPrice = Math.round(tariff.price / tariff.days * 100) / 100;
                    unlimitedTariffs.push({...tariff, tariffDiscount, dayPrice});
                } else {
                    if (!minTariff) {
                        minTariff = tariff;
                    }

                    let tariffDiscount = Math.round((minTariff.price / minTariff.dataSize - tariff.price / tariff.dataSize) / minTariff.price * 100);
                    let gbPrice = Math.round(tariff.price / tariff.dataSize * 100) / 100;
                    tariffs.push({...tariff, tariffDiscount, gbPrice});
                }
                allTariffs.push(tariff);
            }

            dispatchDataState({type: 'setTariffs', payload: allTariffs});
            setTariffs(tariffs);
            setUnlimitedTariffs(unlimitedTariffs);
            if (minTariff) {
                setSelectedTariff(minTariff);
            } else if (minUnlimitedTariff) {
                setSelectedTariff(minUnlimitedTariff);
            }
            dispatchDataState({type: 'setMainButtonVisible', payload: true})
        });
        return () => {
            dispatchDataState({type: 'setMainButtonVisible', payload: false})

        }

    }, [bundle]);

    return (<div className={"container page"}>
        {!bundle ? (<LoadingSpinner/>) : (<div className={"content "}>
            {dataState.bundles == null ? (<LoadingSpinner/>) : (<>
                <BundleHeader bundle={bundle} vertical={true}/>
                <div className={"pagesubtitle-center"}>{localization.GetString("choose_a_data_plan")}</div>
                {tariffs == null ? (<LoadingSpinner/>) : (
                    <>
                        {(tariffs.length > 0 && unlimitedTariffs.length > 0) && <Tabs onSelect={(index) => {
                            setTariffType(index)
                            if (index == 0) {
                                setSelectedTariff(tariffs[0]);
                            } else {
                                setSelectedTariff(unlimitedTariffs[0]);
                            }
                        }} selectedIndex={tariffType}>
                            <TabList>
                                <Tab>{localization.GetString("data_plan_standard")}</Tab>
                                <Tab>{localization.GetString("data_plan_unlimited")}</Tab>
                            </TabList>
                        </Tabs>}

                        <ul className={"tariffs-list"}>

                            {traiffsToShow().map((tariff) => (<li key={tariff.id}
                                                                  className={"radio-list-row " + (tariff.id == selectedTariff.id ? "selected" : "")}
                                                                  onClick={() => {
                                                                      setSelectedTariff(tariff);
                                                                  }}
                            >
                                <div className={"sizes"}>
                                    <div
                                        className={"label"}>{tariff.unlimited ? "∞" : tariff.dataSize} {localization.GetString("gb")}</div>
                                    <div className={"value"}>{tariff.days} {localization.GetString("days")}</div>

                                </div>
                                <div>
                                    <div className={"label"}>{tariff.price} $</div>
                                    {tariff.unlimited ? (
                                        <div
                                            className={"value"}>$ {tariff.dayPrice} / {localization.GetString("day")} </div>
                                    ) : (
                                        <div
                                            className={"value"}>$ {tariff.gbPrice} / {localization.GetString("gb")} </div>
                                    )}
                                </div>
                                <div className={"checkwrap"}>
                                    {tariff.tariffDiscount > 0 && (<div className={"discount"}>
                                        -{tariff.tariffDiscount}%
                                    </div>)}
                                    <div className={"check"}></div>
                                </div>
                            </li>))}
                        </ul>
                    </>)}
                {bundle.countries.length > 1 && (<>
                    <div className={"countries-button "} onClick={() => {
                        setShowCountries(!showCountries);
                    }}>
                        {localization.GetString("available_countries")} {bundle.countries.length}
                    </div>
                    <ul className={"bundle-countries " + (showCountries ? "opened" : "")}>
                        {countryNames.map((country, idx) => (<li key={idx}>{country}</li>))}
                    </ul>
                </>)}
            </>)}
            {(tariffType === 1) && (<div className={"bundle-info"}>
                <p className={"title"}>{localization.GetString("unlimited_title")}</p>
                {localization.GetString("unlimited_description")}
            </div>)}
            {(tariffType === 0) && bundle.startsImmediately && (<div className={"bundle-info time-attention"}>
                {localization.GetString("starts_immediately_description")}
            </div>)}
            <div className={"bundle-info no-number"}>
                <p className={"title"}>{localization.GetString("data_only_title")}</p>
                {localization.GetString("data_only_description")}
            </div>
            {bundle.description && (<div className={"bundle-info"}>
                {localization.GetLocalized(bundle.description)}
            </div>)}
        </div>)
        }

    </div>)
        ;
}
