import {useNavigate} from "react-router-dom";
import React, {useContext, useEffect} from "react";
import {LocalizationContext} from "../../common/Locaization";
import {DataStateContext} from "../../context/dataState";
import {AppStateContext} from "../../context/appState";
import PromoCodeActivation from "../../components/PromoCodeActivation";
import {CreateDepositPayment, DepositHistory} from "../../common/Api";
import {LoadingSpinner} from "../../components/LoadingSpinner";
import balanceLogo from "../../assets/balance-logo.svg";

import config from "../../config";

export default function DepositInfo() {
    const navigation = useNavigate();
    const localization = useContext(LocalizationContext);
    const [dataState, dispatchDataState] = useContext(DataStateContext);
    const [appState, dispatchAppState] = useContext(AppStateContext);
    const [history, setHistory] = React.useState(null);
    const [needRefresh, setNeedRefresh] = React.useState(true);
    const [amount, setAmount] = React.useState(10);
    const [delta, setDelta] = React.useState(10);

    useEffect(() => {
        dispatchAppState({type: 'setNeedUserRefresh', payload: true});
    }, [dispatchAppState]);

    useEffect(() => {
        if (!needRefresh) {
            return;
        }
        DepositHistory().then(data => {
            setHistory(data);
            setNeedRefresh(false);
        });
    }, [needRefresh]);

    const typeName =
        (transaction) => {
            if (transaction.transactionType === "codeTopUp") {
                return localization.GetString("deposit_history_type_code_topup");
            } if (transaction.transactionType === "referralBonus") {
                return localization.GetString("deposit_history_referral_bonus");
            } else if (transaction.transactionType === "orderPayment") {
                return localization.GetString("deposit_history_type_order_payment");
            } else if (transaction.transactionType === "directTopUp") {
                return localization.GetString("deposit_history_type_topup");
            } else if (transaction.transactionType === "orderRefund" || transaction.transactionType === "unsuccessfulOrderRefund") {
                return localization.GetString("deposit_history_type_refund");
            }
        }
    const typeClassName = (transaction) => {
        if (transaction.transactionType === "codeTopUp") {
            return "blue"
        } else if (transaction.transactionType === "orderPayment") {
            return "red"
        } else {
            return ""
        }
    }
    const setAmountValue = (value) => {
        value = parseFloat(value);
        if (value < 0) {
            value = 0;
        }
        if (value > 500) {
            value = 500;
        }
        if (value < 10) {
            value = 10;
        }
        value = Math.floor(value * 100) / 100;

        setAmount(value);
    }
    return (
        <div className={"container page"}>
            <div className={"content"}>
                <div className={"balance-title"}>
                    <img
                        src={balanceLogo}/> {localization.GetString("deposit_balance_title")}
                    <div className={"value"}> $ {dataState.userData.balance}</div>
                </div>
                <div className={"deposit-topup"}>
                    <div className={"sides"}>
                        <div className={"subtitle-text"}>
                            {localization.GetString("deposit_topup")}
                        </div>
                        <div className={"amount-wrap"}>
                            <div className={"decrease"} onClick={() => {
                                setAmountValue(amount - delta);
                            }}>-
                            </div>
                            $&nbsp;<input type={"number"} value={amount} onChange={(e) => {
                                setAmountValue(e.target.value);
                            }}/>
                            <div className={"increase"} onClick={() => {
                                setAmountValue(amount + delta);
                            }}>+
                            </div>
                        </div>
                    </div>

                    <div className={"amount-variations"}>
                        <div className={"amount"} onClick={() => {
                            setAmountValue(amount + 10);
                            //setDelta(10);
                        }}>$ 10
                        </div>
                        <div className={"amount"} onClick={() => {
                            setAmountValue(amount + 20);
                            //setDelta(20);
                        }}>$ 20
                        </div>

                        <div className={"amount"} onClick={() => {
                            setAmountValue(amount + 50);
                            //setDelta(50)
                        }}>$ 50
                        </div>
                        <div className={"amount"} onClick={() => {
                            setAmountValue(amount + 100);
                            //setDelta(100);
                        }}>$ 100
                        </div>
                    </div>
                    <button className={"big-button"} disabled={!amount} onClick={() => {
                        if (config.tonkeeper) {
                            CreateDepositPayment(amount, null, window.source).then((data) => {
                                if (data == null) {
                                    //ToDo: show error
                                    return;
                                }
                                navigation(`/pay?id=${data.paymentId}&target=deposit`);

                            });
                        } else {
                            navigation(`/deposit/payment?amount=${amount}`);
                        }
                    }}>
                        {localization.GetString("deposit_do_topup_button")}
                    </button>

                </div>
                <p className={"info-title"}>
                    {localization.GetString("deposit_how_to_use_title")}
                </p>
                <p className={"info-text"}
                   dangerouslySetInnerHTML={{__html: localization.GetString("deposit_how_to_use_text")}}/>
                <div className={"pagesubtitle"}>
                    {localization.GetString("deposit_history_title")}
                </div>
                <div className={"history"}>
                    {history == null ? (<LoadingSpinner/>) : history.map((item, index) => {
                        return <div key={index} className={"history-item " + typeClassName(item)}>
                            <div className={"date"}>{new Date(item.createdAt).toLocaleString()}</div>
                            <div className={"break"}/>
                            <div className={"amount"}>{item.income ? "+" : "-"} $ {item.amount}</div>
                            <div className={"type"}>{typeName(item)}</div>
                        </div>
                    })}
                </div>
            </div>
        </div>
    )
}
