import React, {useContext} from "react";
import {DataStateContext} from "../../context/dataState";
import {AppStateContext} from "../../context/appState";
import {LocalizationContext} from "../../common/Locaization";
import Header from "../../components/Header";
import ListRow from "../../elements/ListRow";
import profileLanguageEnIcon from "../../assets/profile-language-en.svg";
import profileLanguageRuIcon from "../../assets/profile-language-ru.svg";
import {SaveProfile, SetLanguage} from "../../common/Api";


export default function Language() {
    const localization = useContext(LocalizationContext);
    const [appState, dispatchAppState] = useContext(AppStateContext);
    const [dataState, dispatchDataState] = useContext(DataStateContext);
    const selectLanguage = (language) => {
        SaveProfile({language}).then((data) => {
            localization.SetLanguage(language);
            dispatchDataState({type: 'updateUserData', payload: data});
            dispatchAppState({type: "popNavigationAction"});
        });
    }
    return (
        <div className={"container page"}>
            <div className={"content"}>
                <ListRow selected={dataState.userData.language==="en"} selectable={true} imageUrl={profileLanguageEnIcon} label={"English"} onClick={() => {

                    selectLanguage("en");
                }}/>
                <ListRow selected={dataState.userData.language==="ru"} selectable={true} imageUrl={profileLanguageRuIcon} label={"Русский"} onClick={() => {
                    selectLanguage("ru");
                }}/>
            </div>
        </div>
    );


}
