import React, {useContext, useState} from "react";
import {LocalizationContext} from "../common/Locaization";
import {ActivatePromoCode} from "../common/Api";
import {AppStateContext} from "../context/appState";

export default function PromoCodeActivation({onSuccess}) {
    const localization = useContext(LocalizationContext);
    const [promoCode, setPromoCode] = useState("");
    const [appState, dispatchAppState] = useContext(AppStateContext);
    const getResultText = (result) => {

        if (result == null) {
            return "";
        }

        if (result.amount > 0) {
            if (result.type === "discount" || result.type === "referral") {
                return localization.GetString("promocode_success_discount") + " " + result.amount + "%";
            }
            return localization.GetString("promocode_success") + " $ " + result.amount;
        }
        if (result.result === "referralUnavailable") {

            return localization.GetString("promocode_failed_referral_unavailable");

        }
        return localization.GetString("promocode_failed");
    }
    const [result, setResult] = useState(null);
    return (
        <div className={"promo-code-activation " + (result == null ? "" : result.amount > 0 ? "success" : "failed")}>
            <div className={"input-wrap"}>
                <input disabled={result?.amount > 0} maxLength={30}
                       placeholder={localization.GetString("promocode_placeholder")}
                       value={promoCode}
                       onChange={(e) => {
                           setPromoCode(e.target.value);
                           setResult(null);
                       }}/>
                {!(result?.amount > 0) && (<button disabled={promoCode.length === 0} onClick={() => {
                    if (promoCode.length === 0) {
                        return;
                    }
                    ActivatePromoCode(promoCode).then((data) => {
                        setResult(data);
                        dispatchAppState({type: 'setNeedUserRefresh', payload: true});
                        if (data != null) {
                            if (onSuccess) {
                                onSuccess(data);
                            }
                        }
                    });
                }}>OK
                </button>)}
            </div>
            <p className={"result"}>{getResultText(result)}</p>

        </div>)
}
