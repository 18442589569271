import React, {useContext, useEffect, useMemo, useState} from "react";
import {QRCode} from "react-qrcode-logo";
import {LocalizationContext} from "../../common/Locaization";
import Header from "../../components/Header";
import BundleHeader from "../../elements/BundleHeader";
import {StringDate} from "../../common/DateHelper";
import {useParams} from "react-router-dom";
import {GetSimCard} from "../../common/Api";
import {LoadingSpinner} from "../../components/LoadingSpinner";
import qrLogo from "../../assets/logo_mobile_white.png";
import copyIcon from "../../assets/copy.svg";
import installIcon from "../../assets/install.svg";
import {CopyToClipboard} from "react-copy-to-clipboard/src";


export default function SimCardInfo(factory, deps) {
    const localization = useContext(LocalizationContext);
    const {id} = useParams();
    const [simCard, setSimCard] = useState(null);

    useEffect(() => {
        GetSimCard(id).then(data => {
            setSimCard(data);
        });
    }, [id]);
    const isIos = useMemo(() => {
        if (/iPhone/.test(navigator.userAgent) && !window.MSStream) {
            const match = navigator.userAgent.match(/iPhone OS (\d+_\d+)/);
            if (match) {
                return match[1].replace('_', '.') * 1 >= 17.4;
            }
        }
        return null;
    }, []);

    return (<div className={"container page"}>
        {!simCard ? (
            <LoadingSpinner/>) : (simCard.activated && simCard?.dataPackages?.some(d => (d.status !== "registered" && d.status !== "notActive"))) ?
            simCard.dataPackages.map((bundle) => (<div className={"content"}>
                    <BundleHeader bundle={bundle}/>
                    <div className={"data-item-details"}>
                        <div className={"row"}>
                            <div className={"label"}>ICCID</div>
                            <div className={"value"}>
                                {simCard.iccid}
                            </div>
                        </div>
                        <div className={"row"}>
                            <div className={"label"}>{localization.GetString("my_esims_date")}</div>
                            <div
                                className={"value"}>{StringDate(bundle.createdAt)}</div>

                        </div>

                        <div className={"row"}>
                            <div className={"label"}>{localization.GetString("my_esims_activation_date")}</div>
                            <div
                                className={"value"}>{StringDate(bundle.activatedAt)}</div>

                        </div>
                        <div className={"row"}>
                            <div className={"label"}>{localization.GetString("my_esims_expiration_date")}</div>
                            <div
                                className={"value"}>{StringDate(bundle.expireAt)}</div>

                        </div>

                        {(bundle.status !== "deactivated") && (
                            <div className={"row"}>
                                <div className={"label"}>{localization.GetString("my_esims_remaining_days")}</div>
                                <div
                                    className={"value"}>{bundle.remainingDays ? bundle.remainingDays : localization.GetString("data_loading")}</div>

                            </div>)}
                        {(bundle.status !== "deactivated") && (
                            <div className={"row"}>
                                <div className={"label"}>{
                                    bundle.unlimited ? localization.GetString("my_esims_unlimited_data") : localization.GetString("my_esims_remaining_data")}</div>
                                <div
                                    className={"value"}>{bundle.remainingData ? bundle.remainingData + localization.GetString("gb") : localization.GetString("data_loading")}</div>

                            </div>)}
                    </div>
                </div>)
            ) : (<>
                <p className={"pagetitle-center"}>{localization.GetString("install-esim")}</p>
                <div className={"text-page"}>

                    <div className={"qr-info"}>
                        <div className={"wrapper"}>
                            <div className={"qr-inner"}>
                                <QRCode size={180} value={simCard.qrCode} fgColor={"#0096F9"} logoImage={qrLogo}
                                        logoHeight={39}
                                        logoPadding={1} logoWidth={39}/>
                            </div>
                        </div>
                    </div>
                    {isIos && (<div className={"install-button"} onClick={() => {
                        window.Telegram.WebApp.openLink("https://esimsetup.apple.com/esim_qrcode_provisioning?carddata=" + simCard.qrCode)
                    }}>{localization.GetString("install-on-device")} <img src={installIcon}/></div>)}
                </div>
                <div className={"text-page"}>
                    <p className={"title"}>{localization.GetString("how-to-install")}</p>
                    <ul>
                        <li>
                            {localization.GetString("how-to-install-step1")}
                        </li>
                        <li>
                            {localization.GetString("how-to-install-step2")}
                        </li>
                        <li>
                            {localization.GetString("how-to-install-step3")}
                        </li>
                        <li>
                            {localization.GetString("how-to-install-step4")}
                        </li>
                    </ul>
                </div>
                <div className={"text-page"}>

                    <p className={"title centred"}>{localization.GetString("how-to-install-manual")}</p>
                    <p className={"centred"}>
                        <b>SM-DP:</b>
                        <CopyToClipboard text={simCard.smDp}>
                            <div className={"copy-field"}>{simCard.smDp}<img src={copyIcon}/></div>
                        </CopyToClipboard>
                    </p>

                    <p className={"centred"}>
                        <b>Activation code:</b>
                        <CopyToClipboard text={simCard.activationCode}>
                            <div className={"copy-field"}>{simCard.activationCode}<img src={copyIcon}/></div>
                        </CopyToClipboard>
                    </p>
                    {simCard.confirmationCode && (<p className={"centred"}>
                            <b>Confirmation code:</b>
                            <CopyToClipboard text={simCard.confirmationCode}>
                                <div className={"copy-field"}>{simCard.confirmationCode}<img src={copyIcon}/></div>
                            </CopyToClipboard>
                        </p>
                    )}
                </div>
                <div className={"text-page"}>
                    <p className={"title"}>{localization.GetString("before-using")}</p>
                    <ul>
                        <li>
                            {localization.GetString("before-using-step1")}
                        </li>
                        <li>
                            {localization.GetString("before-using-step2")}
                        </li>
                        <li>
                            {localization.GetString("before-using-step3")}
                        </li>
                        <li>
                            {localization.GetString("before-using-step4")}
                        </li>
                        <li>
                            {localization.GetString("before-using-step5")}
                        </li>
                    </ul>

                </div>
                <p className={"centred"}>{localization.GetString("before-using-contact")} <a onClick={() => {
                    window.Telegram.WebApp.openTelegramLink("https://t.me/MobileSuppBot")
                }}>@MobileSuppBot</a></p>
            </>)}
    </div>)


}
