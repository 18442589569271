import "./common/bufferPolyfill"
import React from 'react';
import ReactDOM from 'react-dom/client';
import './scss/app.scss';
import App from './App';
import * as Sentry from "@sentry/react";
import config from "./config";

if (config.errorTracking) {
    Sentry.init({
        dsn: "https://50c280939cdef940ea9a89827d6c41cd@o4508332154486784.ingest.de.sentry.io/4508332157829200",
        integrations: [
            Sentry.browserTracingIntegration(),
            Sentry.replayIntegration(),
        ],
        // Tracing
        tracesSampleRate: 1.0, //  Capture 100% of the transactions
        // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
        tracePropagationTargets: ["localhost", /^https:\/\/yourserver\.io\/api/],
        // Session Replay
        replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
        replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
    });
}
const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
    <App/>
);
