import {CreateOrder, PayPayment} from "../common/Api";
import {ExpressCheckoutElement, useElements, useStripe} from "@stripe/react-stripe-js";
import React, {useContext} from "react";
import {DataStateContext} from "../context/dataState";
import {useNavigate} from "react-router-dom";

export default function StripeCheckout({tariffId, payAmount, balanceUse, discountCode}) {
    const elements = useElements();
    const stripe = useStripe();
    const [dataState, dispatchDataState] = useContext(DataStateContext);
    const navigation = useNavigate();

    return (
        <ExpressCheckoutElement onConfirm={async (data) => {
            if (!stripe) {
                return;
            }

            const {error: submitError} = await elements.submit();
            if (submitError) {
                return;
            }

            const order = await CreateOrder(tariffId, dataState.expressCheckout.id, window.source, balanceUse, discountCode?.code);
            debugger;
            const payment = await PayPayment(order.paymentId, true, data.expressPaymentType);
            const clientSecret = payment.token;
            const {error} = await stripe.confirmPayment({
                // `elements` instance used to create the Express Checkout Element
                elements,
                // `clientSecret` from the created PaymentIntent
                clientSecret,
                confirmParams: {
                    return_url: `https://${window.location.host}/order/${order.id}/processing`,
                },
                redirect: 'if_required'
            });
            if (error) {
                console.log('Payment failed:', error);
            } else {
                navigation(`/order/${order.id}/processing`);
            }

        }}/>
    );
}
