import React, {useContext, useEffect, useState} from "react";
import {useNavigate, useParams} from "react-router-dom";
import ProgressLogo from "../assets/progress-logo.svg";
import {GetOrder, OrderStatus} from "../common/Api";
import {LocalizationContext} from "../common/Locaization";

export default function OrderProcessing() {
    const {id} = useParams();
    const [status, setStatus] = useState("created");
    const localization = useContext(LocalizationContext);

    const navigation = useNavigate();

    useEffect(() => {
            const interval = setInterval(async () => {
                const status = await OrderStatus(id);
                console.log("status", status);
                if (status.status === "completed") {
                    const order = await GetOrder(id);
                    navigation("/simcards/" + order.simCard.iccid, {replace: true});
                } else {
                    setStatus(status.status);
                }
            }, 1000);
            return () => clearInterval(interval);
        }, [id, navigation]
    );

    return (
        <div className={"container page"}>
            <div className={"info-page"}>
                {(status === "created" || status === "paid") ? (<>
                        <img src={ProgressLogo}/>

                        <h2>{localization.GetString("order-processing")}</h2>
                        <p>{localization.GetString("order-processing-text")}</p>
                    </>
                ) : (<>
                        <h2>{localization.GetString("order-failed")}</h2>
                        <p>{localization.GetString("order-failed-text")}</p></>
                )}
            </div>
        </div>
    );
}
