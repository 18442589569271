import React, {useContext, useEffect} from "react";
import {LocalizationContext} from "../../common/Locaization";
import {DataStateContext} from "../../context/dataState";
import {useNavigate} from "react-router-dom";

export default function DepositTopUp() {
    const localization = useContext(LocalizationContext);
    const [amount, setAmount] = React.useState(0);
    const [delta, setDelta] = React.useState(1);
    const [dataState, dispatchDataState] = useContext(DataStateContext);
    const navigation = useNavigate();
    useEffect(() => {


        dispatchDataState({
            type: 'setMainButton', payload: {
                text: localization.GetString("deposit_do_topup_button"), onClick: () => {

                }
            }
        })

        dispatchDataState({type: 'setMainButtonVisible', payload: amount > 0})
        return () => {
            dispatchDataState({type: 'setMainButtonVisible', payload: false})
            dispatchDataState({type: 'setMainButton', payload: null})
        }
    }, [amount]);


    const setAmountValue = (value) => {
        value = parseFloat(value);
        if (value < 0) {
            value = 0;
        }
        if (value > 10000) {
            value = 10000;
        }
        value = Math.floor(value * 100) / 100;

        setAmount(value);
    }
    return (
        <div className={"container page"}>



        </div>
    );
}
