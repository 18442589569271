import React from "react";

export function DataStateReducer(state, action) {

    switch (action.type) {
        case "updateUserData":
            return {
                ...state, userData: action.payload
            };
        case "updateCountries":
            return {
                ...state, countries: action.payload
            };
        case "updateBundles":
            return {
                ...state, bundles: action.payload
            };
        case "setOrder":
            return {
                ...state, orders: {...state.orders, [action.payload.id]: action.payload}
            };
        case "setLoading":
            return {
                ...state, loading: action.payload
            };
        case "updatePaymentMethods":
            return {
                ...state, paymentMethods: action.payload
            };
        case "setTariffs":
            return {
                ...state, tariffs: [...(state.tariffs ?? []), ...action.payload]
            };
        case "setMainButton":
            return {
                ...state, mainButton: action.payload
            };
        case "setMainButtonVisible":
            return {
                ...state, mainButtonVisible: action.payload
            };
        case "setExpressCheckout":
            return {
                    ...state, expressCheckout: action.payload
            };
        default:
            return state;
    }

}

export const DataStateContext = React.createContext({});
