import React, {useContext, useEffect, useMemo, useState} from "react";
import ListRow from "../../elements/ListRow";
import {LocalizationContext} from "../../common/Locaization";
import profileEsimIcon from "../../assets/profile-esims.svg";
import profileOrdersIcon from "../../assets/profile-orders.svg";
import profileLanguageIcon from "../../assets/profile-language.svg";
import profileThemeIcon from "../../assets/profile-theme.svg";
import {AppStateContext} from "../../context/appState";
import {DataStateContext} from "../../context/dataState";
import {useNavigate} from "react-router-dom";
import config from "../../config";
import UserContent from "../../components/UserContent";
import balanceLogo from "../../assets/balance-logo.svg";
import PromoCodeActivation from "../../components/PromoCodeActivation";
import referralImg from "../../assets/referral.png";

export default function Profile() {
    const localization = useContext(LocalizationContext);
    const [appState, dispatchAppState] = useContext(AppStateContext);
    const [dataState, dispatchDataState] = useContext(DataStateContext);
    const navigation = useNavigate();

    return (<div className={"container page"}>
            <UserContent>
                <div className={"content"}>
                    <div className={"pagetitle"}>{localization.GetString("account")}</div>

                    <div className={"list-tile"}>{localization.GetString("profile_deposit")}</div>
                    <div onClick={() => {
                        navigation("/deposit/info/")
                    }}
                         className={"list-row"}>

                        <div style={{backgroundImage: `url(${balanceLogo})`}} className={"icon"}/>
                        <div className={"label"}>{localization.GetString("deposit_balance")}
                            <div className={"value-black"}>
                                $ {dataState.userData.balance}</div>
                        </div>


                    </div>
                    <PromoCodeActivation/>
                    {config.referralEnabled && (
                        <div className={"referral-banner"} onClick={() => {
                            navigation("/referral")
                        }}>
                            <div className={"info"}>
                                {
                                    localization.GetString("referral_banner")
                                }
                                {/* <div className={"btn"}>{localization.GetString("referral_banner_button")}</div>*/}
                            </div>
                            <img src={referralImg} className={"image"}/>
                        </div>)}

                    <div className={"list-tile"}>{localization.GetString("profile")}</div>
                    <ListRow imageUrl={profileEsimIcon} label={localization.GetString("my_esims")} onClick={() => {
                        navigation("/simcards")
                    }}/>
                    <ListRow imageUrl={profileOrdersIcon} label={localization.GetString("orders")} onClick={
                        () => {
                            navigation("/orders")
                        }
                    }/>
                    {!config.website && (<>

                        <div className={"list-tile"}>{localization.GetString("settings")}</div>
                        <ListRow imageUrl={profileLanguageIcon}
                                 label={localization.GetString("language")}
                                 value={localization.GetString("language_" + dataState.userData.language)}
                                 onClick={() => {
                                     navigation("/language")
                                 }}/>
                        <ListRow imageUrl={profileThemeIcon} label={localization.GetString("theme")}
                                 value={localization.GetString("theme_" + dataState.userData.theme)} onClick={() => {
                            navigation("/theme")
                        }}/>
                        <div className={"list-tile"}>{localization.GetString("profile_other")}</div>
                        <ListRow label={localization.GetString("privacy_notes")} onClick={() => {
                            navigation("/info/privacy-notes");
                        }}/>
                        <ListRow label={localization.GetString("complaints_policy")} onClick={() => {
                            navigation("/info/complaints-policy");
                        }}/>
                        <ListRow label={localization.GetString("terms_and_conditions")} onClick={() => {
                            navigation("/info/terms-and-conditions");
                        }}/>
                        <ListRow label={localization.GetString("bug_bounty")} onClick={() => {
                            navigation("/info/bug-bounty");
                        }}/>
                        <ListRow label={localization.GetString("contact_us")} onClick={() => {
                            window.Telegram.WebApp.openTelegramLink("https://t.me/MobileSuppBot");
                        }}/>
                        <ListRow label={localization.GetString("faq")} onClick={() => {
                            navigation("/faq");
                        }}/>
                    </>)}
                </div>
            </UserContent>
        </div>
    );
}
