import React, {useContext, useEffect, useState} from "react";
import Header from "../components/Header";
import {Tab, TabList, TabPanel, Tabs} from "react-tabs";
import BundlesList from "../elements/BundlesList";
import {DataStateContext} from "../context/dataState";
import BundlesGroup from "../elements/BundlesGroup";
import {LocalizationContext} from "../common/Locaization";
import {AppStateContext} from "../context/appState";
import NotFound from "../elements/NotFound";
import {useNavigate, useParams} from "react-router-dom";

export default function Bundles({mode}) {
    const localization = useContext(LocalizationContext);
    const [appState, dispatchAppState] = useContext(AppStateContext);
    const [dataState, dispatchDataState] = useContext(DataStateContext);
    const [found, setFound] = useState(null);
    const navigation = useNavigate();
    const {searchText} = useParams();

    useEffect(() => {
        if (mode !== "search") {
            setFound(null);
            return;
        }
        if (!dataState.countries || !dataState.bundles) {
            return;
        }

        if (!searchText) {
            setFound(null);
            return;
        }

        const countries = dataState.countries.filter(country => country.searchNames.some(name => name.toLowerCase().includes(searchText.toLowerCase()
        ))).map(country => country.code);
        const bundles = dataState.bundles.filter(bundle => bundle.countries.some(country => countries.includes(country) || bundle.name.values.ru.toLowerCase().includes(searchText.toLowerCase()) || bundle.name.values.en.toLowerCase().includes(searchText.toLowerCase())));
        if (bundles.length === 0) {
            setFound({success: false});
            return;
        }
        setFound({
            success: true,
            country: bundles.filter(f => f.type === "country").sort((a, b) => b.popularity - a.popularity),
            region: bundles.filter(f => f.type === "region").sort((a, b) => b.popularity - a.popularity),
            global: bundles.filter(f => f.type === "global").sort((a, b) => b.popularity - a.popularity),
        });


    }, [searchText, dataState.countries, dataState.bundles]);

    return (
        <div className={"container page"}>
            <Header useSearch={true} searchText={searchText}/>
            <div className={"content"}>
                <div className={"main-title"}>
                    {localization.GetString("data_plan")}
                </div>
                {found ? (<>
                    {found.success ? (<>
                        {found.country.length > 0 && (<> <p
                            className={"pagetitle"}>{localization.GetString("category_countries")}</p>
                            <BundlesList
                                bundles={found.country}/></>)}
                        {found.region.length > 0 && (<> <p
                            className={"pagetitle"}>{localization.GetString("category_regions")}</p>
                            <BundlesList
                                bundles={found.region}/></>)}
                        {found.global.length > 0 && (<> <h2>{localization.GetString("category_global")}</h2><BundlesList
                            bundles={found.global}/></>)}
                    </>) : (<NotFound search={true} text={localization.GetString("no_results_found")}/>)}
                </>) : (
                    <Tabs onSelect={(index) => {
                        if (index === 0) {
                            navigation("/", { replace: true });
                        } else if(index===1) {
                            navigation("/regions", { replace: true });
                        } else {
                            navigation("/global", { replace: true }
                            );
                        }
                    }} selectedIndex={mode === "region" ? 1 : mode=="global"?2:0}>
                        <TabList onChange={(e) => {

                        }}>
                            <Tab>{localization.GetString("category_countries")}</Tab>
                            <Tab>{localization.GetString("category_regions")}</Tab>
                            <Tab>{localization.GetString("category_global")}</Tab>
                        </TabList>

                        <TabPanel>
                            <p className={"pagetitle"}>{localization.GetString("popular_countries")}</p>
                            <BundlesGroup type={"country"} showPopular={true}/>
                            <p className={"pagetitle"}>{localization.GetString("all_countries")}</p>
                            <BundlesGroup type={"country"} alphabeticalSort={true}/>
                        </TabPanel>
                        <TabPanel>
                            <p className={"pagetitle"}>{localization.GetString("category_regions")}</p>
                            <BundlesGroup type={"region"} showPopular={false}/>
                        </TabPanel>
                        <TabPanel>
                            <p className={"pagetitle"}>{localization.GetString("category_global")}</p>
                            <BundlesGroup type={"region"} global={true} showPopular={false}/>
                        </TabPanel>
                    </Tabs>)}
            </div>
        </div>
    );
}
