import React, {useContext} from "react";
import {LoadingSpinner} from "../components/LoadingSpinner";
import {LocalizationContext} from "../common/Locaization";
import {DataStateContext} from "../context/dataState";
import ListRow from "./ListRow";
import config from "../config";

export default function PaymentMethods({paymentMethods, onSelect}) {
    const localization = useContext(LocalizationContext);
    const [dataState, dispatchDataState] = useContext(DataStateContext);

    return (<>
        {paymentMethods == null ? (<LoadingSpinner/>) : (<div className={"payment-methods"}>
            <div className={"subtitle"}>
                {localization.GetString("payment_methods")}
            </div>
            <div>
                {paymentMethods.map((method) => (
                    <ListRow key={method.id} imageUrl={config.imgUrl + "payment-methods/" + method.iconName}
                             label={localization.GetLocalized(method.name)}
                             onClick={() => {
                                 onSelect(method);

                             }}/>))}
            </div>
        </div>)}
    </>);
}
