import React, {useContext, useEffect, useMemo, useState} from "react";
import Payment from "../elements/Payment";
import {LocalizationContext} from "../common/Locaization";
import ProgressLogo from "../assets/progress-logo.svg";
import {GetOrder, OrderStatus, PaymentStatus, PayPayment} from "../common/Api";
import {LoadingSpinner} from "../components/LoadingSpinner";
import {useNavigate, useParams, useSearchParams} from "react-router-dom";
import {useTonConnectUI} from "@tonconnect/ui-react";
import {beginCell, toNano} from '@ton/ton'
import config from "../config";


export default function Pay() {
    const [paymentStatus, setPaymentStatus] = useState("created");
    const [paymentData, setPaymentData] = useState(null); // payment
    const [order, setOrder] = useState(null);
    const localization = useContext(LocalizationContext);

    const [searchParams] = useSearchParams();
    const {id, target, targetId} = useMemo(() => {
        return {
            id: searchParams.get("id"), target: searchParams.get("target"), targetId: searchParams.get("targetId")
        };
    }, [searchParams]);
    const navigation = useNavigate();


    const [tonConnectUI] = useTonConnectUI();
    const finalNavigate = async (status) => {
        if (status === "success") {
            if (target === "order") {

                navigation(`/order/${targetId}/processing`, {replace: true});
            } else if (target === "deposit") {

            }
        } else if (status === "failed") {

            navigation("/pay/result/payment-failed", {replace: true});
        }
    }


    const resetNavigation = async function () {
        return new Promise(async (resolve, reject) => {
            while (true) {
                await new Promise(r => setTimeout(r, 10));
                if (window.location.pathname !== "/pay") {
                    resolve();
                    return;
                }
                window.history.back();
            }
        });
    }

    useEffect(() => {
        PayPayment(id).then(async (data) => {
            if (data.status && data.status !== "created") {
                setPaymentStatus(data.status);
                await finalNavigate(data.status);
            }
            if (config.tonkeeper) {
                const body = beginCell()
                    .storeUint(0, 32) // write 32 zero bits to indicate that a text comment will follow
                    .storeStringTail(data.comment) // write our text comment
                    .endCell();
                tonConnectUI.sendTransaction({
                    validUntil: Math.floor(Date.now() / 1000) + 60, // 60 sec
                    messages: [{
                        address: data.address,
                        amount: toNano(data.amount).toString(),
                        payload: body.toBoc().toString("base64")
                    }]
                }).then((response) => {

                }).catch((error) => {
                    navigation("/pay/result/method-failed", {replace: true});
                });
            }

            setPaymentData(data);
        }).catch(error => {
            navigation("/pay/result/method-failed", {replace: true});
        });
    }, [id, navigation, tonConnectUI]);

    useEffect(() => {
        if (paymentData == null || paymentStatus !== "created") {
            return;
        }
        const interval = setInterval(async () => {
            const statusResult = await PaymentStatus(id);
            if (paymentStatus !== statusResult.status) {
                setPaymentStatus(statusResult.status);
            }
            if (statusResult.status !== "created") {
                clearInterval(interval);
                await resetNavigation();
                await finalNavigate(statusResult.status);
            }


        }, 2000);
        return () => clearInterval(interval);
    }, [paymentData, paymentStatus, order, id, target, navigation, targetId]);

    if (paymentData == null) {
        return (<div className={"container page"}>
            <LoadingSpinner/>
        </div>);
    }
    if (config.tonkeeper) {
        return (<div className={"container page"}>
            <div className={"info-page"}>
                <img src={ProgressLogo}/>
                <h2>{localization.GetString("paymant-progress-ton")}</h2>
                <p>{localization.GetString("paymant-progress-ton-text")}</p>
            </div>
        </div>);
    }

    if (paymentStatus === "failed") {
        return (<div className={"container page"}>
            <div className={"info-page"}>
                <h2>{localization.GetString("payment-failed")}</h2>
                <p>{localization.GetString("payment-failed-text")}</p>
            </div>
        </div>);
    }
    if (paymentStatus === "success") {
        return (<></>);
    }

    return (<div className={"container page"}>
        <Payment paymentData={paymentData}/>
    </div>);

}
