import {useNavigate, useSearchParams} from "react-router-dom";
import React, {useContext, useEffect, useMemo, useState} from "react";
import {LocalizationContext} from "../../common/Locaization";
import config from "../../config";
import {CreateDepositPayment, GetPaymentMethods} from "../../common/Api";
import PaymentMethods from "../../elements/PaymentMethods";

export default function DepositPayment() {
    const [searchParams] = useSearchParams();
    const navigation = useNavigate();
    const localization = useContext(LocalizationContext);
    const [paymentMethods, setPaymentMethods] = useState(null);
    useEffect(() => {
        if (paymentMethods) {
            return;
        }

        GetPaymentMethods().then(data => {
            setPaymentMethods(data);
        });

    }, [paymentMethods]);
    const {amount} = useMemo(() => {
        const amount = searchParams.get("amount");
        if (amount === null || amount === undefined || isNaN(amount) || amount < 0) {
            navigation("/");
            return {amount: 0}
        }
        return {
            amount: amount
        };
    }, [searchParams]);
    return (
        <div className={"container page"}>
            <div className={"content"}>
                <div className={"pagetitle-center"}>{localization.GetString("deposit_payment_title")} $ {amount}</div>
                {paymentMethods && <PaymentMethods paymentMethods={paymentMethods} onSelect={(paymentMethod) => {
                    CreateDepositPayment(amount, paymentMethod.id, window.source).then((data) => {
                        if (data == null) {
                            //ToDo: show error
                            return;
                        }
                        navigation(`/pay?id=${data.paymentId}&target=deposit`, {replace: true});
                    });
                }}/>}
            </div>
        </div>
    )
}
