import React, {useContext, useEffect} from "react";
import Payframe from "./payframe";
import config from "../config";
import TonPayment from "./TonPayment";
import ProgressLogo from "../assets/progress-logo.svg";
import {LocalizationContext} from "../common/Locaization";

export default function Payment({paymentData}) {
    const localization = useContext(LocalizationContext);

    if (paymentData.type === "url") {
        if (paymentData.isTelegramUrl) {
            window.Telegram.WebApp.openTelegramLink(paymentData.url);
            return (<div className={"info-page"}>
                <img src={ProgressLogo}/>
                <h2>{localization.GetString("payment-waiting")}</h2>
                <button className={"big-button"} onClick={() => {
                    window.Telegram.WebApp.openTelegramLink(paymentData.url);
                }}>{localization.GetString("payment-open-form")}</button>

            </div>);
        }
        return (<Payframe onload={(e) => {
            debugger;
            console.log("frame loaded", e);
        }} url={paymentData.url}/>)

    }
    if (paymentData.type === "ton") {
        return (<TonPayment paymentData={paymentData}/>)
    }
    return (<></>);
}
