import React, {useContext, useEffect, useMemo, useState} from "react";
import {BottomSheet} from 'react-spring-bottom-sheet'
import {LocalizationContext} from "../common/Locaization";
import HelpRow from "../elements/HelpRow";
import {useNavigate} from "react-router-dom";

export default function HelpBalloon() {
    const [open, setOpen] = useState(false);
    const localization = useContext(LocalizationContext);
    const navigation = useNavigate();
    const onlineStatus = useMemo(() => {
        const date = new Date();
        if (date.getUTCHours() >= 6 && date.getUTCHours() < 19) {
            return (
                <div className={"online"}>
                    <div className={"marker"}/>
                    {localization.GetString("help_supoort_online")}</div>);
        }

        const openDate = new Date();
        openDate.setUTCHours(6);
        openDate.setUTCMinutes(0);
        return localization.GetString("help_support_will_be_online_at") + openDate.getHours() + ":" + ("0" + openDate.getMinutes()).substring(-2);
    }, [localization, open]);
    return (
        <>
            {!open && <div className={"help-balloon"} onClick={() => {
                setOpen(true);
            }}/>}
            <BottomSheet
                onDismiss={() => setOpen(false)}
                header={<div>{localization.GetString("help_title")}
                    <div className={"help-close"} onClick={() => setOpen(false)}></div>
                </div>}
                open={open}>
                <div className={"help-content"}>
                    <HelpRow title={localization.GetString("help_what_is_esim")}
                             description={localization.GetString("help_what_is_esim_description")}
                             onLinkClick={() => {
                                 navigation("faq?section=0");
                                 setOpen(false);
                             }}
                    />
                    <HelpRow title={localization.GetString("help_setup_and_activation")}
                             description={localization.GetString("help_setup_and_activation_description")}
                             onLinkClick={() => {
                                 navigation("faq?section=1");
                                 setOpen(false);
                             }}
                    />
                    <HelpRow title={localization.GetString("help_adjusting_esim")}
                             description={localization.GetString("help_adjusting_esim_description")}
                             onLinkClick={() => {
                                 navigation("faq?section=2");
                                 setOpen(false);
                             }}
                    />
                    <HelpRow title={localization.GetString("help_support")}
                             description={onlineStatus}
                             onLinkClick={() => {
                                 window.Telegram.WebApp.openTelegramLink("https://t.me/MobileSuppBot");
                                 setOpen(false);
                             }}
                    />
                </div>
            </BottomSheet>
        </>
    )
}
