import React, {useContext, useEffect} from "react";
import QRCode from "react-qr-code";
import {LocalizationContext} from "../common/Locaization";
import {CopyToClipboard} from "react-copy-to-clipboard/src";
import config from "../config";
import {DataStateContext} from "../context/dataState";
import CopyRow from "../components/Copy";

export default function TonPayment({paymentData}) {
    const localization = useContext(LocalizationContext);
    const [dataState, dispatchDataState] = useContext(DataStateContext);
    const goToCheckout = () => {
        window.Telegram.WebApp.openLink(config.tonRedirectUrl + "?url=" + encodeURIComponent(paymentData.url));
    }
    useEffect(() => {

        dispatchDataState({type: 'setMainButtonVisible', payload: true})

        dispatchDataState({
            type: 'setMainButton', payload: {text: localization.GetString("go_to_checkout"), onClick: goToCheckout}
        })
        return () => {
            dispatchDataState({type: 'setMainButtonVisible', payload: false})
            dispatchDataState({type: 'setMainButton', payload: null})
        }

    }, [paymentData])
    return (<div className={"text-page"}>

        <div className={"qr-info"}>
            <QRCode value={paymentData.url}/>
        </div>
        <p>
            {localization.GetString("ton_payment_info_description")}
        </p>
        <p className={"textfield-label"}>
            {localization.GetString("ton_payment_info_address")}
        </p>
        <CopyRow  flex={false} text={paymentData.address}>
            <p className={"textfield-value"}>
                {paymentData.address}
            </p></CopyRow>
        <p className={"textfield-label"}>
            {localization.GetString("ton_payment_info_comment")}
        </p>
        <CopyRow  flex={false} text={paymentData.comment}>
            <p className={"textfield-value"}>
                {paymentData.comment}
            </p>
        </CopyRow>
        <p className={"textfield-label"}>
            {localization.GetString("ton_payment_info_amount")}
        </p>
        <CopyRow flex={false} text={paymentData.amount}>
            <p className={"textfield-value"}>
                {paymentData.amount}
            </p>
        </CopyRow>


    </div>);
}
