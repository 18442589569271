import React from 'react';

const messages = {
    "countries": {
        values: {
            "en": "Countries", "ru": "Страны"
        }
    },
    "regions": {
        values: {
            "en": "Regions", "ru": "Регионы"
        }
    },
    "search_placeholder": {
        values: {
            "en": "Search a country or plan", "ru": "Поиск страны или тарифа"
        }
    },
    "no_results_found": {
        values: {
            "en": "Nothing found. Try changing your search parameters",
            "ru": "Ничего не найдено. Попробуйте изменить параметры поиска"
        }
    },
    "app_title": {
        values: {
            "en": "Mobile Market", "ru": "Mobile Market"
        }
    },
    "app_subtitle": {
        values: {
            "en": "bot", "ru": "bot"
        }
    },
    "data_plan": {
        values: {
            "en": "eSIM with Mobile data", "ru": "eSIM с Интернетом"
        }
    },
    "category_countries": {
        values: {
            "en": "Countries", "ru": "Страны"
        }
    },
    "category_regions": {
        values: {
            "en": "Regions", "ru": "Регионы"
        }
    },
    "category_global": {
        values: {
            "en": "Global", "ru": "Глобальные"
        }
    },
    "popular_countries": {
        values: {
            "en": "Popular countries", "ru": "Популярные страны"
        }
    },
    "all_countries": {
        values: {
            "en": "All countries", "ru": "Все страны"
        }
    },
    "choose_a_data_plan": {
        values: {
            "en": "Choose a data plan", "ru": "Выберите тариф"
        }
    },
    "data_plan_standard": {
        values: {
            "en": "Standard", "ru": "Стандартный"
        }
    },
    "data_plan_unlimited": {
        values: {
            "en": "Unlimited ∞", "ru": "Безлимитный ∞"
        }
    },
    "details_data": {
        values: {
            "en": "Data", "ru": "Данные"
        }
    },
    "details_validity": {
        values: {
            "en": "Validity", "ru": "Срок действия"
        }
    },
    "details_deposit": {
        values: {
            "en": "Deposit:", "ru": "Депозит:"
        }
    },
    "details_discount_code": {
        values: {
            "ru": "Промокод",
            "en": "Promocode"
        }
    },
    "details_total": {
        values: {
            "en": "Total:", "ru": "Итого:"
        }
    },
    "use_deposit": {
        values: {
            "en": "Use deposit", "ru": "Использовать депозит"
        }
    },
    "use_deposit_available": {
        values: {
            "en": "Available", "ru": "Доступно"
        }
    },

    "purchase_button": {
        values: {
            "en": "Purchase", "ru": "Купить"
        }
    },
    "gb": {
        values: {
            "en": "GB", "ru": "ГБ"
        }
    },
    "day": {
        values: {
            "en": "Day", "ru": "День"
        }
    },
    "days": {
        values: {
            "en": "Days", "ru": "Дней"
        }
    },
    "payment_methods": {
        values: {
            "en": "Payment methods", "ru": "Способы оплаты"
        }
    },
    "go_to_checkout": {
        values: {
            "en": "Go to checkout", "ru": "Оплатить"
        }
    },
    "connect_ton": {
        values: {
            "en": "Connect TON", "ru": "Подключить TON"
        }
    },
    "available_countries": {
        values: {
            "en": "Available countries: ", "ru": "Доступные страны: "
        }
    },
    "order-processing": {
        values: {
            "en": "Payment successful", "ru": "Оплата прошла успешно"
        }
    },
    "order-processing-text": {
        values: {
            "ru": "Ваш платеж успешно обработан. Идет активация тарифа.",
            "en": "Your payment was successful. Activating tariff now."
        }
    },
    "order-failed": {
        values: {
            "ru": "Что-то пошло не так",
            "en": "Something went wrong"
        }
    },
    "order-failed-text": {
        values: {
            "ru": "Возникла ошибка при обработке заказа. Мы уже работаем над ее устранением. В случае необходимости обратитесь в поддержку.",
            "en": "An error occurred while processing the order. We are already working to fix it. If necessary, contact support."
        }
    },
    "payment-failed": {
        values: {
            "en": "Payment failed", "ru": "Ошибка оплаты"
        }
    },
    "payment-failed-text": {
        values: {
            "en": "Your payment was not successful. Please try again.", "ru": "Оплата не прошла. Попробуйте еще раз."
        }
    },
    "payment-unknown-status": {
        values: {
            "ru": "Что-то пошло не так",
            "en": "Something went wrong"
        }
    },
    "payment-unknown-text": {
        values: {
            "ru": "Пожалуйста повторите заказ. Если оплата была произведена обратитесь в поддержку.",
            "en": "Please try again. If payment was made contact support."
        }
    },
    "payment-method-failed": {
        values: {
            "ru": "Ошибка оплаты",
            "en": "Payment error"
        }
    },
    "payment-method-failed-text": {
        values: {
            "ru": "Платеж не прошел. Попробуйте повторить попытку или выберите другой способ оплаты.",
            "en": "Payment failed. Please try again or choose another payment method."

        }
    },
    "order-progress": {
        values: {
            "ru": "Обработка заказа",
            "en": "Order processing"
        }
    },
    "order-progress-text": {
        values: {
            "ru": "Ваш заказ обрабатывается. Пожалуйста, подождите.",
            "en": "Your order is being processed. Please wait."
        }
    },
    "paymant-progress-ton": {
        values: {
            "ru": "Обработка транзакции",
            "en": "Transaction processing"
        }
    },
    "paymant-progress-ton-text": {
        values: {
            "ru": "Подтвердите транзакцию и дождитесь подтверждения",
            "en": "Confirm the transaction and wait for confirmation"
        }
    },
    "payment-waiting": {
        values: {
            "ru": "Ожидание оплаты",
            "en": "Waiting for payment"
        }
    },
    "payment-open-form":
        {
            values: {
                "ru": "Открыть форму оплаты",
                "en": "Open payment form"
            }
        },
    "install-esim": {values: {"en": "Install eSIM", "ru": "Установка eSIM"}},
    "how-to-install": {values: {"en": "How to install eSIM", "ru": "Как установить eSIM"}},
    "install-on-device": {
        values: {
            "en": "Install eSIM on this device",
            "ru": "Установить eSIM на это устройство"
        }
    },
    "how-to-install-step1": {
        values: {
            "en": "Forward or take a photo of the QR code using another device.",
            "ru": "Сделайте фотографию или перешлите QR-код на другое устройство."
        }
    },
    "how-to-install-step2": {
        values: {
            "en": "Ensure a reliable Wi-Fi connection during installation.",
            "ru": "Убедитесь, что у вас есть стабильное соединение по Wi-Fi во время установки."
        }
    },
    "how-to-install-step3": {
        values: {
            "en": "Scan the QR code with the phone for eSIM installation.",
            "ru": " Отсканируйте QR-код телефоном, на который вы хотите установить eSIM."
        }
    },
    "how-to-install-step4": {
        values: {
            "en": "The QR code can only be used once. Do not delete it, as re-installation is not possible.",
            "ru": "Помните, что QR-код можно использовать только один раз. Не удаляйте его, так как повторная установка невозможна."
        }
    },
    "how-to-install-manual": {
        values: {
            "en": "For manual installation, use the provided data:",
            "ru": "Для ручной установки используйте следующие данные:",
        }
    },
    "before-using": {
        values: {
            "ru": "Перед использованием eSIM убедитесь, что:",
            "en": "Before Using eSIM, ensure:"
        }
    },
    "before-using-step1": {
        values: {
            "en": "eSIM is installed.",
            "ru": "eSIM установлена."
        }
    },
    "before-using-step2": {
        values: {
            "en": "You are in the region where your plan works.",
            "ru": "Вы находитесь в регионе, где работает ваш тарифный план."
        }
    },
    "before-using-step3": {
        values: {
            "en": "Mobile data is enabled.",
            "ru": "Мобильные данные включены."
        }
    },
    "before-using-step4": {
        values: {
            "en": "eSIM is set as your mobile data plan.",
            "ru": "eSIM установлена как ваш план для мобильных данных."
        }
    },
    "before-using-step5": {
        values: {
            "en": "Data roaming for eSIM is enabled.",
            "ru": "Роуминг данных для eSIM включен."
        }
    },
    "before-using-contact": {
        values: {
            "en": "For assistance, contact",
            "ru": "Если вам нужна помощь, обратитесь к нам в"
        }
    },


    "payment-error": {
        values: {
            "en": "Payment error. Please try again or choose another payment method.",
            "ru": "Ошибка оплаты. Попробуйте еще раз или выберите другой способ оплаты."
        }
    },
    "ton_payment_info_description": {
        values: {
            "ru": "Для оплаты вам нужно совершить платеж по указаным ниже реквизитам. Вы можете отсканировать QR код или воспользоваться кнопкой оплаты.",
            "en": "To pay, you need to make a payment using the details below. You can scan the QR code or use the payment button.",
        }
    },
    "ton_payment_info_address": {
        values: {
            "ru": "Адрес кошелька",
            "en": "Wallet address"
        }
    },
    "ton_payment_info_amount": {
        values: {
            "ru": "Сумма",
            "en": "Amount"
        }
    },
    "ton_payment_info_comment": {
        values: {
            "ru": "Комментарий",
            "en": "Comment"
        }
    },
    "ton_payment_info_button": {
        values: {
            "ru": "Оплатить",
            "en": "Pay"
        }
    },
    "account": {
        values: {
            "ru": "Аккаунт",
            "en": "Account"
        }
    },
    "my_account": {
        values: {
            "ru": "Мой аккаунт",
            "en": "My account"
        }
    },
    "profile": {
        values: {
            "ru": "Профиль",
            "en": "Profile"
        }
    },
    "settings": {
        values: {
            "ru": "Настройки",
            "en": "Settings"
        }
    },
    "my_esims": {
        values: {
            "ru": "Мои eSIM",
            "en": "My eSIMs"
        }
    },
    "my_esims_active": {
        values: {
            "ru": "Активные",
            "en": "Active"
        }
    },
    "my_esims_archived": {
        values: {
            "ru": "Архивные",
            "en": "Archived"
        }
    },


    "my_esims_remaining_days": {
        values: {
            "ru": "Осталось дней",
            "en": "Remaining days"
        }
    },
    "my_esims_date": {
        values: {
            "ru": "Дата покупки",
            "en": "Purchase date"
        }
    },
    "my_esims_activation_date": {
        values: {
            "ru": "Дата активации",
            "en": "Activation date"
        }
    },
    "my_esims_expiration_date": {
        values: {
            "ru": "Дата окончания",
            "en": "Expiration date"
        }
    },
    "my_esims_remaining_data": {
        values: {
            "ru": "Осталось данных",
            "en": "Remaining data"
        }
    },
    "my_esims_unlimited_data": {
        values: {
            "ru": "Осталось данных на высокой скорости",
            "en": "Remaining high-speed data"
        }
    },
    "my_esims_no_active": {
        values: {
            "ru": "Нет активных eSIM",
            "en": "No active eSIMs"
        }
    },
    "my_esims_no_archived": {
        values: {
            "ru": "Нет архивных eSIM",
            "en": "No archived eSIMs"
        }
    },
    "data_loading": {
        values: {
            "ru": "-",
            "en": "-"
        }
    },
    "orders": {
        values: {
            "ru": "Заказы",
            "en": "Orders"
        }
    },
    "language": {
        values: {
            "ru": "Язык",
            "en": "Language"
        }
    },
    "language_ru": {
        values: {
            "ru": "Русский",
            "en": "Russian"
        }
    },
    "language_en": {
        values: {
            "ru": "Английский",
            "en": "English"
        }
    },

    "theme": {
        values: {
            "ru": "Тема",
            "en": "Theme"
        }
    },
    "theme_light": {
        values: {
            "ru": "Светлая",
            "en": "Light"
        }
    },
    "theme_dark": {
        values: {
            "ru": "Темная",
            "en": "Dark"
        }
    },
    "theme_system": {
        values: {
            "ru": "Системная",
            "en": "System"
        }
    },
    "my_orders": {
        values: {
            "ru": "Мои заказы",
            "en": "My orders"
        }
    },
    "order_status": {
        values: {
            "ru": "Статус",
            "en": "Status"
        }
    },
    "order_status_created": {
        values: {
            "ru": "Создан",
            "en": "Created"
        }
    },
    "order_status_paid": {
        values: {
            "ru": "Оплачен",
            "en": "Paid"
        }
    },
    "order_status_paymentFailed": {
        values: {
            "ru": "Ошибка оплаты",
            "en": "Payment failed"
        }
    },
    "order_status_completed": {
        values: {
            "ru": "Завершен",
            "en": "Completed"
        }
    },
    "order_status_failed": {
        values: {
            "ru": "Ошибка",
            "en": "Failed"
        }
    },
    "order_status_expired": {
        values: {
            "ru": "Истек",
            "en": "Expired"
        }
    },
    "order_status_canceled": {
        values: {
            "ru": "Отменен",
            "en": "Canceled"
        }
    },
    "order_date": {
        values: {
            "ru": "Дата",
            "en": "Date"
        }
    },
    "order_payment_date": {
        values: {
            "ru": "Дата оплаты",
            "en": "Payment date"
        }
    },
    "order_payment_method": {
        values: {
            "ru": "Способ оплаты",
            "en": "Payment method"
        }
    },
    "get_invoice": {
        values: {
            "ru": "Счет",
            "en": "Invoice"
        }
    },
    profile_deposit: {
        values: {
            "ru": "Депозит",
            "en": "Deposit"
        }
    },
    profile_other: {
        values: {
            "ru": "Другое",
            "en": "Other"
        }
    },
    privacy_notes: {
        values: {
            "ru": "Политика конфиденциальности",
            "en": "Privacy notes"
        }
    },
    complaints_policy: {
        values: {
            "ru": "Политика жалоб",
            "en": "Complaints policy"
        }
    },
    terms_and_conditions: {
        values: {
            "ru": "Условия и положения",
            "en": "Terms and Conditions"
        }
    },
    bug_bounty: {
        values: {
            "ru": "Bug bounty program",
            "en": "Bug bounty program"
        }
    },
    contact_us: {
        values: {
            "ru": "Связаться с нами",
            "en": "Contact us"
        }
    },
    data_only_title: {
        values: {
            "ru": "Только данные и звонки через Интернет",
            "en": "Data and calls over the Internet only"
        }
    },
    data_only_description: {
        values: {
            "en": "This plan does not come with a number.",
            "ru": "Этот план не имеет номера телефона."
        }
    },
    starts_immediately_description: {
        values: {
            "en": "Attention! The validity period of the tariff starts immediately after successful payment!",
            "ru": "Внимание! Срок действия тарифа начинается сразу после успешной оплаты!."
        }
    },
    unlimited_title: {
        values: {
            "en": "1 GB at full speed every day!",
            "ru": "1 ГБ на максимальной скорости каждый день!"
        }
    },
    unlimited_description: {
        values: {
            "en": "You get 1 GB of high-speed data every day. Once used up, the speed is reduced to 512 kbps.",
            "ru": "Каждый день вы получаете 1 ГБ на максимальной скорости. После исчерпания трафика скорость снижается до 512 Кбит/с."
        }
    },
    faq: {
        values: {
            "ru": "Часто задаваемые вопросы",
            "en": "FAQ"
        }
    },

    help_title: {
        values: {
            "ru": "Поддержка Mobile",
            "en": "Mobile Support"
        }
    },
    help_what_is_esim: {
        values: {
            "ru": "Что такое eSIM?",
            "en": "What is eSIM?"
        }
    },
    help_what_is_esim_description: {
        values: {
            "ru": "Основные понятия",
            "en": "Basic concepts"
        }
    },
    help_setup_and_activation: {
        values: {
            "ru": "Установка и активация еЅІM",
            "en": "eSIM setup and activation"
        }
    },
    help_setup_and_activation_description: {
        values: {
            "ru": "Пошаговые инструкции",
            "en": "Step-by-step instructions"
        }
    },
    help_adjusting_esim: {
        values: {
            "ru": "Настройка eSIM",
            "en": "Adjusting eSIM"
        }
    },
    help_adjusting_esim_description: {
        values: {
            "ru": "Решение проблем с подключением",
            "en": "Solving connection problems"
        }
    },
    help_support: {
        values: {
            "ru": "Написать в техподдержку",
            "en": "Write to support"
        }
    },
    help_supoort_online: {
        values: {
            "ru": "Онлайн",
            "en": "Online"
        }
    },
    help_support_will_be_online_at: {
        values: {
            "ru": "Будем онлайн с ",
            "en": "Offline now, online from "
        }
    },
    promocode_placeholder: {
        values: {
            "ru": "Промокод",
            "en": "Promocode"
        }
    },
    promocode_success: {
        values: {
            "ru": "Успешно. Вам начислено ",
            "en": "Success. You credited "
        }
    },
    promocode_success_discount: {
        values: {
            "ru": "Успешно. К вашему заказу будет применена скидка ",
            "en": "Success. To your order will be applied discount "


        }
    },
    promocode_failed: {
        values: {
            "en": "Invalid promocode",
            "ru": "Неверный промокод"
        }
    },
    "promocode_failed_referral_unavailable": {
        values: {
            "ru": "Вы не можете использовать пригласительный промокод",
            "en": "You can't use referral promocode"
        }
    },
    "deposit_balance": {
        values: {
            "ru": "Баланс:",
            "en": "Balance:"
        }
    },
    "deposit_topup_button": {
        values: {
            "ru": "Пополнить",
            "en": "Top up"
        }
    },
    "deposit_topup": {
        values: {
            "ru": "Пополнить депозит",
            "en": "Top up deposit"
        }
    },
    "deposit_do_topup_button": {
        values: {
            "ru": "Оплатить",
            "en": "Pay"
        }
    },
    "deposit_how_to_use_title": {
        values: {
            "ru": "Как использовать депозит",
            "en": "How to use deposit"
        }
    },
    "deposit_how_to_use_text": {
        values: {
            "ru": "<ul><li>Пополните счет (банковский перевод, электронные платежи).</li><li> Используйте средства для покупки тарифных планов</li><li>Контролируйте баланс и пополняйте депозит при необходимости.</li>",
            "en": "<ul><li>Top up your account (bank transfer, electronic payments).</li><li>Use the funds to purchase tariff plans</li><li>Monitor the balance and top up the deposit as needed.</li>"
        }
    },
    "deposit_payment_title": {
        values: {
            "ru": "Оплата депозита",
            "en": "Deposit payment"
        }
    },
    "deposit_balance_title": {
        values: {
            "en": "Balance",
            "ru": "Баланс"
        }
    },
    "deposit_history_title": {
        values: {
            "en": "History",
            "ru": "История"

        }
    },
    "deposit_history_type_code_topup": {
        values:
            {
                "ru": "Aктивация промокода",
                "en": "Promocode activation"
            }
    },
    "deposit_history_referral_bonus": {
        values:
            {
                "ru": "Реферальный бонус",
                "en": "Referral bonus"
            }
    },
    "deposit_history_type_order_payment": {
        values:
            {
                "ru": "Оплата заказа",
                "en": "Order payment"
            }
    },
    "deposit_history_type_topup": {
        values:
            {
                "ru": "Пополнение",
                "en": "Top up"
            }
    },
    "deposit_history_type_refund": {
        values: {
            "ru": "Возврат",
            "en": "Refund"
        }
    },
    "copy": {
        values: {
            "ru": "Копировать",
            "en": "Copy"
        }
    },
    "copied": {
        values: {
            "ru": "Скопировано",
            "en": "Copied"
        }
    },
    "referral_banner": {
        values: {
            "ru": "Получай $3 на депозит за каждого приглашенного друга, а они получат 20% скидку на первую покупку!",
            "en": "Get $3 deposited for each friend you invite, and they get a 20% discount on their first purchase!"
        }
    },
    "referral_banner_button": {
        values: {
            "en": "Start now!",
            "ru": "Начать сейчас!"
        }
    },
    "referral_title": {
        values: {
            "en": "Referral program",
            "ru": "Реферальная программа"
        }
    },
    "referral_subtitle": {
        values: {
            "ru": "Получай бонус 3$ за каждого приглашенного друга на баланс депозита, а твой друг получит скидку 20% на первую покупку",
            "en": "Get a $3 bonus for each friend you invite to your deposit balance, and your friend will receive a 20% discount on their first purchase."
        }
    },
    "referral_invited": {
        values: {
            "ru": "Приглашено",
            "en": "Invited"
        }
    },
    "referral_reward_received": {
        values: {
            "ru": "Получено",
            "en": "Received"
        }
    },
    "referral_code": {
        values: {
            en: "Your referral code",
            ru: "Ваш реферальный код"
        }
    },
    "referral_description1_title": {
        values: {
            en: "Invite Your Friends",
            ru: "Пригласите своих друзей"
        }
    },
    "referral_description1": {
        values: {
            en: "Share your unique code or click the \"Send Invitation\" button to tell them about us.\n",
            ru: "Поделитесь своим уникальным кодом или нажмите кнопку \"Отправить приглашение\", чтобы рассказать о нас."
        }
    },
    "referral_description2_title": {
        values: {
            ru: "Друзья получают скидку 20%",
            en: "Friends Get a 20% Discount"
        }
    },
    "referral_description2": {
        values: {
            ru: "Приглашенные друзья получают скидку {discount}% на первую покупку.",
            en: "Invited friends get a {discount}% discount on their first purchase."
        }
    },
    "referral_description3_title": {
        values: {
            en: "Earn $3 for Each Friend You Invite",
            ru: "Зарабатывайте $3 за каждого приглашенного\n"
        }
    },
    "referral_description3": {
        values: {
            en: "You earn $3 for each friend who completes their first purchase.",
            ru: "Вы получаете $3 за каждого друга, который завершит свою первую покупку."
        }
    },
    "referral_share": {
        values: {
            ru: "Отправить приглашение",
            en: "Send Invitation"
        }
    },
    "referral_share_text": {
        values: {
            ru: "Привет! Пробовал ли ты использовать eSIM? Получи 20% скидку на свой первый eSIM пакет от Mobile. Используй код {code} или перейди по ссылке",
            en: "Hey! Have you tried using an eSIM? Get a 20% discount on your first eSIM package from Mobile. Use code {code} or follow the link:"
        }
    }

}


export const localization = {
    language: null, SetLanguage: function (lang) {
        this.language = lang;
    }, GetLocalized: function (localizedMessage) {
        if (!this.language || localizedMessage?.values[this.language] == null) {
            return "";
        }
        return localizedMessage.values[this.language];
    },

    GetString: function (key, params = null) {

        let localized = this.GetLocalized(messages[key]);

        if (localized == null) {
            return key;
        }

        if (params != null) {
            for (let key in params) {
                localized = localized.replace("{" + key + "}", params[key]);
            }
            return localized;
        }
        return localized;
    }
}
export const LocalizationContext = React.createContext({});
