import React, {useContext} from "react";
import arrow from "../assets/arrow-right.svg";

export default function ListRow({imageUrl, label, value, onClick, imageHeight, image, selectable, selected}) {
    return (
        <div onClick={onClick}
             className={"list-row " + (selectable ? "radio-list-row " : " ") + (selected ? "selected " : " ")}>
            {image ? image : imageUrl ? (
                <div style={{backgroundImage: `url(${imageUrl})`}} className={"icon"}/>) : null}

            <div className={"label"}>{label}</div>
            {value && (<div className={"value"}>{value}</div>)}
            {selectable && (<div className={"check"}></div>)}
        </div>
    );
}
