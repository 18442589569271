import React, {useContext, useEffect, useState} from "react";
import {DataStateContext} from "../context/dataState";
import {useTonConnectUI, useTonWallet} from "@tonconnect/ui-react";
import config from "../config";
import {LoadingSpinner} from "./LoadingSpinner";

export default function UserContent({children}) {
    const wallet = useTonWallet();
    const [dataState, dispatchDataState] = useContext(DataStateContext);
    const [tonConnectUI] = useTonConnectUI();
    const [checkTimeout, setCheckTimeout] = useState(null);
    useEffect(() => {
        if (checkTimeout) {
            clearTimeout(checkTimeout);
        }
        if (config.tonkeeper && !wallet && !tonConnectUI.connected) {
            if (!wallet) {
                setCheckTimeout(setTimeout(() => {

                    tonConnectUI.openSingleWalletModal("tonkeeper").then((data) => {
                        console.log(data);
                    });
                }, 1000));
            }
        }
    }, [tonConnectUI, wallet]);
    if (config.tonkeeper && !wallet) {
        return (<LoadingSpinner/>);
    }

    return children;

}
